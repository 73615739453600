import Vue from "vue";
// @ts-ignore
import CreateTabs from "@/views/Admin/Commons/Tabs.vue";
// @ts-ignore
import i18n from "@/plugins/i18n";
// @ts-ignore
import TabCampaigns from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/Campaigns/index.vue";
import TabTotal from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/Total/index.vue";
// import TabSocioDemographic from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/SocioDemographic/index.vue";
// import TabPoisInformation from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/PoisInformation/index.vue";
// import TabDate from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/Date/index.vue";
// import TabResidencePlace from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/ResidencePlace/index.vue";
// import TabDigitalBehavior from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/DigitalBehavior/index.vue";
// import TabConnectionType from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/ConnectionType/index.vue";
import TabTables from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/TabTables/index.vue";
// import TabGenderAndAge from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/GenderAndAge/index.vue";
// import TabAttributions from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/Attributions/index.vue";
// import TabRichAttributions from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/RichAttributions/index.vue";
import AudienceExport from "@/views/Admin/Persons/V10/Dependencies/Dialogs/ExportAudience/index.vue";
import FloatingActions from "@/views/Admin/Persons/V10/Dependencies/Common/FloatingActions/index.vue";
import SectionButtons from "@/views/Admin/Persons/V10/Dependencies/Common/SectionButtons/index.vue";
import { mapActions, mapGetters } from "vuex";
import { TypeLoading } from "@/interfaces/loading";
import { isEqual, last } from "lodash";
import { sleep } from "@/utils/convert";
import {
	AUDIENCE_ACTIONS,
	FloatingAction,
} from "@/interfaces/persons/v10/audience";
import InformationSection from "../Dependencies/Tabs/Audience/Sections/InformationSection/index.vue";
import BidRequestSection from "../Dependencies/Tabs/Audience/Sections/BidRequestSection/index.vue";
import DemoHomesSection from "../Dependencies/Tabs/Audience/Sections/DemoHomesSection/index.vue";
import {
	AudienceSectionItem,
	AudienceStrategy,
	audiencePanelIdentifier,
	initialPanel,
} from "@/models/persons/v10/Audience";
import { makeUnique } from "@/utils/filter-global";

const DEMO = "demo_home_audience";
const INFO = "hour_information";
const BID = "bid_request_audience";

export interface IFilterData {
	type: string;
	name: string;
}

export default Vue.extend({
	name: "PersonsAudience",
	props: {},
	components: {
		CreateTabs,
		TabCampaigns,
		TabTotal,
		InformationSection,
		BidRequestSection,
		DemoHomesSection,
		TabTables,
		SectionButtons,
		AudienceExport,
		FloatingActions,
	},
	data: () => ({
		currentTab: 1,
		formattedData: {},
		savedPanel: [],
		modal: false,
		exportScreenModal: false,
		showExportAudienceModal: false,
		wasLoaded: false,
		panels: [0] as number[],
	}),
	created() {},
	mounted() {
		this.$nextTick(async () => {
			this.currentTab = this.isTabAudience ? 1 : 2;
			this.panels = this.isTabAudience ? [0, 1, 2, 3] : [0, 1, 2, 3, 4];
			this.setLoadingData(TypeLoading.loading);
			await this.initialize();
			this.setLoadingData();
			this.fetchAll();
			this.wasLoaded = true;
		});
	},
	computed: {
		...mapGetters("audience", {
			filterHasChanges: "filterHasChanges",
			hasFilters: "hasFilters",
			loadingData: "loadingData",
			getStrategy: "getStrategy",
			canFilter: "canFilters"
		}),

		isDummyRich() {
			return Boolean(process.env.VUE_APP_ENABLE_STORE_ATTRIBUTION_FAKE);
		},

		isTabAudience() {
			return this.$route.name == "PersonsAudience";
		},

		getFloatingActions(): FloatingAction[] {
			return [
				{
					action: AUDIENCE_ACTIONS.BACK,
					icon: "mdi-arrow-left",
					disabled: false,
					loading: false,
					title: this.$t("Persons10.panelAudience.buttons.edit_poi"),
				},
				{
					action: AUDIENCE_ACTIONS.EXPORT_AUDIENCE,
					icon: "mdi-account-arrow-right",
					disabled: false,
					loading: false,
					title: this.$t(
						"Persons10.panelAudience.buttons.export_audience"
					),
				},
				{
					action: AUDIENCE_ACTIONS.EXPORT_SCREEN,
					icon: "mdi-monitor-screenshot",
					disabled: this.loadingData,
					loading: this.loadingData,
					title: this.$t(
						"Persons10.panelAudience.buttons.export_screen"
					),
				},
				{
					action: AUDIENCE_ACTIONS.RESET,
					icon: "mdi-filter-remove",
					disabled: !this.hasFilters || this.loadingData,
					loading: this.loadingData,
					title: this.$t(
						"Persons10.panelAudience.buttons.reset_filter"
					),
				},
				{
					action: AUDIENCE_ACTIONS.FILTER,
					icon: "mdi-filter-cog",
					disabled: this.isFilterDisabled,
					loading: this.loadingData,
					title: this.getFilterTitle,
					forceTooltip: !this.getStrategy.isAllSectionSelected,
				},
			];
		},

		getTabItems() {
			return [
				{
					key: 0,
					tab: i18n.t("persons.tabs.pois"),
					disabled: true,
				},
				{
					key: 1,
					tab: i18n.t("persons.tabs.audience"),
					disabled: !this.isTabAudience,
				},
				{
					key: 2,
					tab: i18n.t("persons.tabs.store-attribution"),
					disabled: this.isTabAudience,
				},
			];
		},

		isFilterDisabled() {
			if(!this.canFilter) return true;
			return (
				!this.filterHasChanges ||
				this.loadingData ||
				!this.getStrategy.isAllSectionSelected
			);
		},

		getFilterTitle() {
			if (!this.getStrategy.isAllSectionSelected) {
				return this.$t(
					"Persons10.panelAudience.buttons.first_select_all_section"
				);
			}

			return this.$t("Persons10.panelAudience.buttons.filter");
		},
	},
	methods: {
		...mapActions("audience", [
			"initialize",
			"fetchAll",
			"fetchFilters",
			"clearFilters",
			"exportAudience",
			"fetchReadyFilterAudience"
		]),
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("export", ["exportPdf"]),

		customSort(a: AudienceSectionItem, b: AudienceSectionItem): number {
			const order = audiencePanelIdentifier;
			const indexA = order.indexOf(a.name);
			const indexB = order.indexOf(b.name);
			return indexA - indexB;
		},

		async handleAction(action: AUDIENCE_ACTIONS) {
			switch (action) {
				case AUDIENCE_ACTIONS.FILTER:
					this.handleClickFilter();
					break;
				case AUDIENCE_ACTIONS.RESET:
					await this.clearFilters(false);

					if (this.filterHasChanges) {
						this.handleClickFilter();
					}
					break;
				case AUDIENCE_ACTIONS.EXPORT_SCREEN:
					this.handleExportScreen();
					break;
				case AUDIENCE_ACTIONS.EXPORT_AUDIENCE:
					this.handleOpenExportAudienceModal();
					break;
				case AUDIENCE_ACTIONS.BACK:
					this.handleEditPoi();
					break;
				default:
					alert("e");
			}
		},

		handleEditPoi() {
			this.modal = true;
		},

		handleYes() {
			// this.$router.push({
			// 	name: "PersonsIndex",
			// 	query: { from: "audience" }
			// })
			// .then(() => window.location.reload());
			window.location.href = `${process.env.VUE_APP_FRONT_URL}/admin/persons/index?from=audience`;
		},

		handleNo() {
			this.modal = false;
		},

		handleClickFilter() {
			this.fetchFilters();
		},

		handleCloseExportAudienceModal() {
			this.showExportAudienceModal = false;
		},

		handleOpenExportAudienceModal() {
			this.showExportAudienceModal = true;
		},

		handleExportAudience(data) {
			this.exportAudience(data);
			this.handleCloseExportAudienceModal();
		},

		async handleExportScreen() {
			if (!isEqual(initialPanel, this.panels)) {
				this.exportScreenModal = true;
			} else {
				this.handleExportScreenYes();
			}
		},

		async handleExportScreenNo() {
			this.exportScreenModal = false;
			this.savedPanel = this.panels;
			this.panels = initialPanel;
			this.setLoadingData(TypeLoading.loading);
			await sleep(50);
			await this.exportPdf("export-audience");
			this.panels = this.savedPanel;
			this.setLoadingData();
		},

		async handleExportScreenYes() {
			this.exportScreenModal = false;
			this.setLoadingData(TypeLoading.loading);
			await this.exportPdf("export-audience");
			this.setLoadingData();
		},

		deleteFilter(filter) {
			const filterIndex = this.filters.findIndex(
				(item) => item == filter
			);
			this.filters.splice(filterIndex, 1);
		},

		getDiffElements(
			oldArray: AudienceSectionItem[],
			newArray: AudienceSectionItem[]
		) {
			return oldArray.filter(
				(o) => !newArray.some((n) => n.name === o.name)
			);
		},
	},
	watch: {
		"loadingData"(old, val){
			if(val){
				this.fetchReadyFilterAudience();
			}
		}
	},
});
